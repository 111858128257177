import React from "react";
import { Layout } from 'antd';
import Sider from "../sider";
// import Header from "../header";

const BaseLayout : React.FC = (props) => {
    return (
        <Layout className={"admin-layout"}>
            <Sider/>
            <Layout>
                {/*<Header/>*/}
                <Layout.Content className={"admin-content"}>
                    {props.children}
                </Layout.Content>
            </Layout>
        </Layout>
    )
}

export default BaseLayout;