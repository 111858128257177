import React from "react";
import {
    LineChartOutlined,
    ProjectOutlined,
    RobotOutlined,
    TeamOutlined,
    TrophyOutlined,
    BookOutlined,
    SoundOutlined,
    AppstoreAddOutlined
} from "@ant-design/icons";

const data = {
    menuItems : [
        {
            title : "Dashboard",
            link  : "/kelola/dashboard",
            icon  : <LineChartOutlined style={{fontSize : 18}} />
        }, {
            title : "Project",
            link  : "/kelola/project",
            icon  : <ProjectOutlined style={{fontSize : 18}} />
        }, {
            title : "Product",
            link  : "/kelola/product",
            icon  : <AppstoreAddOutlined style={{fontSize : 18}} />
        }, {
            title : "Team",
            link  : "/kelola/team",
            icon  : <TeamOutlined style={{fontSize : 18}} />
        }, {
            title : "Client",
            link  : "/kelola/client",
            icon  : <RobotOutlined style={{fontSize : 18}} />
        },{
            title : "Testimonial",
            link  : "/kelola/testimonial",
            icon  : <SoundOutlined style={{fontSize : 18}} />
        }, {
            title : "Award",
            link  : "/kelola/award",
            icon  : <TrophyOutlined style={{fontSize : 18}} />
        }, {
            title : "Blog",
            link  : "/kelola/blog",
            icon  :  <BookOutlined style={{fontSize : 18}}  />
        }
    ]
}

export default data