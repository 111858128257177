import React from 'react';

interface Pages {
    path      : string,
    isPublic  : boolean,
    component : React.LazyExoticComponent<React.FC>
}

let pages : Pages[] = [
    {
        path      : "/kelola/dashboard",
        isPublic  : false,
        component : React.lazy(() => import('../pages/dashboard')),
    },
    {
        path      : "/kelola/project",
        isPublic  : false,
        component : React.lazy(() => import('../pages/project')),
    },
    {
        path      : "/kelola/product",
        isPublic  : false,
        component : React.lazy(() => import('../pages/product')),
    },
    {
        path      : "/kelola/team",
        isPublic  : false,
        component : React.lazy(() => import('../pages/team')),
    },
    {
        path      : "/kelola/client",
        isPublic  : false,
        component : React.lazy(() => import('../pages/client')),
    },
    {
        path      : "/kelola/testimonial",
        isPublic  : false,
        component : React.lazy(() => import('../pages/testimonial')),
    },
    {
        path      : "/kelola/award",
        isPublic  : false,
        component : React.lazy(() => import('../pages/award')),
    },
    {
        path      : "/kelola/blog",
        isPublic  : false,
        component : React.lazy(() => import('../pages/blog')),
    },
    {
        path      : "/",
        isPublic  : true,
        component : React.lazy(() => import('../pages/login')),
    },
]

export default pages